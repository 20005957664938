<template>
  <v-app>
    <DrawerNav v-if="$vuetify.breakpoint.xsOnly"/> <!-- NavigationDrawer with hamburger icon, for portrait/mobile narrow viewport -->
    <AppBarNav v-if="$vuetify.breakpoint.smAndUp"/> <!-- Normal toolbar for desktop/landscape wide viewport -->
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>


<script>
import AppBarNav from '@/components/AppBarNav';
import DrawerNav from '@/components/DrawerNav';

export default {
  components: {
    AppBarNav,
    DrawerNav
  },
  data: () => {
    return {
      // mobileView: true,
    }
  },
}
</script>


<style lang="scss">
#app {
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
}

body, input, select, textarea {
  color: #555f66;
  font-family: "Roboto", Helvetica, sans-serif;
  font-size: 14pt;
  font-weight: 300;
  line-height: 1.75em;
  margin: 0;
}


</style>
