<template>
	<v-container fluid pa-0>
		<v-row>
			<v-col>
				<section id="banner">
				<div class="inner">
					<h2 style="font-size: 2em">SSB Catering</h2> 
					<div class="mysubtitle">Corporate Events Specialist</div> 
					<!-- <h2 style="font-size: 2em"></h2> -->
					<p></p>
				</div>
				</section>
			</v-col>
		</v-row>
		<v-row justify="center" class="px-16 mx-16 my-8">
			<v-col>
				<div class="summary">
				SSB Catering (formerly, Servers Standing By) is a Bay Area catering company that has been specializing in corporate breakfast and lunch programs for over 30 years.  
					Within the past year we have redesigned and changed our company's structure to adapt to the new normal.  
					We have created a "one kitchen, multiple concepts" system that allows clients to choose from a wide variety of cuisines while conveniently working with one caterer.
				</div>
			</v-col>
		</v-row>
		<v-row justify="center" class="greysection py-8 px-16">
			<v-col v-for="c in conceptCards" :key="c.key" cols="12" sm="4">
				<v-card outlined class="pa-5">
					<router-link :to="c.link"><img v-bind:src="require(`../assets/${c.file}`)" height="110" width="110"/></router-link>
					<!-- <img v-bind:src="require(`../assets/${c.file}`)" height="110" width="110"/> -->
					<h3 style="font-size: 1em">{{ c.title }}</h3>
					<br/>
					<p>{{ c.description }}</p>
				</v-card>
			</v-col>
		</v-row>
		<v-row class="mt-16">
			<v-col>
				<h2>Bay Area Catering Service</h2>
				<div class="mysubtitle"><p>San Francisco to the Silicon Valley</p></div>
			</v-col>
		</v-row>
		<v-row justify="center" class="greysection px-16 py-8">
			<v-col v-for="c in aboutCards" :key="c.key" cols="12" sm="4">
				<v-card outlined class="pa-8">
					<router-link :to="c.link"><img v-bind:src="require(`../assets/${c.file}`)" width="170" height="110"/></router-link>
					<h3 style="font-size: 1em">{{ c.title }}</h3>
					<!-- <br/>
					<p>{{ c.description }}</p> -->
				</v-card>
			</v-col>
		</v-row>
		<v-row class="darksection py-16">
			<v-col>
				<!-- <h2>bay area corporate dining</h2> -->
				<!-- <v-row justify="center"  class="px-8">
					<v-col cols="12" sm="5">
						<img v-bind:src="require(`../assets/catering1.jpg`)" class="py-4" style="width:100%"/>
					</v-col>
					<v-col cols="12" sm="5">
						<img v-bind:src="require(`../assets/catering2.jpg`)" class="py-4" style="width:100%"/>
					</v-col>
				</v-row> -->
				<v-row justify="center" class="px-8">
					<v-col cols="12" sm="10">
						<p>Here at SSB Catering, we commit ourselves to delivering quality food within the Bay Area based on each client's special requests and needs. We understand that corporate functions are essential to the foundation of successful businesses. From company picnics to multiple-day retreats or conferences, Servers Standing By Catering provides our clients with delicious, enticing food within their budget and specifications.</p>
					</v-col>
				</v-row>
				<v-btn outlined color="grey" to="/contact">order now</v-btn>
			</v-col>
		</v-row>
		<v-row class="greysection text-left pa-16">
			<v-col>
				<v-row>
					<v-col cols="12" sm="3" class="mx-4">
						<h3>CONTACT US</h3>
						<p/>
						<p>SSB Catering<br/>
							109 South Boulevard<br/>
							San Mateo, CA, 94402</p>
						<p>Phone: (650) 342-5753<br/>
							Fax: (650) 342-5060<br/>
							Email: orders@ssbcatering.com
						</p>
						<p>Follow us:</p>
						<v-btn icon href="https://www.yelp.com/biz/servers-standing-by-catering-san-mateo-3">
							<img height="32" width="32" v-bind:src="require('../assets/yelp.svg')"/>
						</v-btn>
						<v-btn icon href="https://www.facebook.com/ServersStandingBy">
							<img height="32" width="32" v-bind:src="require('../assets/facebook.svg')"/>
						</v-btn>
					</v-col>
					<v-col cols="12" sm="8" class="mx-4">
						<iframe style="width:100%;height:100%" frameborder="0" scrolling="no" src="https://maps.google.ca/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=109+South+Boulevard,+San+Mateo,+CA+94402,+United+States&amp;aq=0&amp;oq=109+South+Boulevard+San+Mateo,+CA,+94402&amp;sll=49.891235,-97.15369&amp;sspn=46.99697,135.263672&amp;ie=UTF8&amp;hq=&amp;hnear=109+South+Blvd,+San+Mateo,+California+94402,+United+States&amp;t=m&amp;z=14&amp;ll=37.554075,-122.312812&amp;output=embed"></iframe>
					</v-col>
				</v-row>
				<v-row class="copyright text-center pa-4 mt-4">
					<p>&copy;SSB Catering. All rights reserved.  <br/> All information provided is provided for information purposes only and does not constitute a legal contract between SSB Catering and any person or entity unless otherwise specified.</p>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>


<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
	name: 'Home',
	components: {
	},

	data() {
		return {
			aboutCards: [
				{ key: 1, file: "ssb_logo4_small.png", title: "Why Us?", link: "/about?tab=why-us", description: "We take pride in serving only the finest, lovingly prepared foods to our Bay Area clients. Our chefs take full advantage of California's fresh-grown produce, meats, and seafood, serving only the absolute best quality food."},
				{ key: 2, file: "canteen4.jpg", title: "Services", link: "/about?tab=our-services", description: "We strive to deliver exceptional service and the perfect dining experience."},
				{ key: 3, file: "meal_kit_delivery2.jpg", title: "Delivery", link: "/about?tab=delivery", description: "We highly value our employees and treat them with the same courtesy as our clients. In our family-run environment, everyone is eager to tend to all your catering needs!"},
			],
			conceptCards: [
				{ key: 1, file: "breakfast_bistro_logo_small.png", title: "Breakfast Bistro", link: "/breakfast", description: "Classic favorites and newly created concepts for your office to enjoy"},
				{ key: 2, file: "lunchly_logo_small.png", title: "Lunchly", link: "/lunchly", description: "The freshest ingredients California has to offer in our European- and American-inspired dishes"},
				{ key: 3, file: "grazi_logo_small.png", title: "Graziano's", link: "/grazi", description: "Grandma's classic Italian, Greek and Mediterranean dishes"},
				{ key: 4, file: "achiote_grill_logo_small.png", title: "Achiote Grill", link: "/achiote", description: "Fusion dishes inspired by Latin American and Caribbean flavors"},
				{ key: 5, file: "shoyu_logo_small.png", title: "Shoyu Asian Eatery", link: "/shoyu", description: "A wide variety of Asian-fusion and Hawaiian favortites"},
			]
		}
	}


}

</script>


<style lang="scss" scoped>

h2 {
	// font-size: 2em;
	line-height: 1em;
	font-weight: 300;
	margin: 0 0 0.5em 0;
	padding: 0;
	letter-spacing: 0.125em;
	text-transform: uppercase;
	opacity: 1;
}

h3 {
	// font-size: 1.4em;
	line-height: 1.25em;
	font-weight: 300;
	letter-spacing: 0.07em;
}

p, li {
	font-weight: 300;
	font-size: 0.8em;
	// letter-spacing: 0.08em;
}
.summary {
	font-size: 1em;
	// letter-spacing: 0.07em;
	// font-weight: 300;
	// color: #bbb;
}

.mysubtitle p {
	// font-weight: 300;
	// font-size: 1.4em;
	letter-spacing: 0.07em;
	font-weight: 300;
	color: #bbb;
}

.darksection {
	background-color: #192024;
	position: relative;
	color: #c5c7c8;
	// font-size: 1em;
	font-weight: 300;
}

.greysection {
	background: #f8f8f8;
}

.dardgreysection {
	background: #f8f8f8;
}

.copyright {
	color: #bbb;
	font-size: 0.9em;
}

#banner {
	background-color: #666666;
	background-image: url("../assets/banner.jpg");
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	color: #d8d8d8;
	padding: 9em 0;
	// margin: 1em 0;
	text-align: center;
	position: relative;
}

	#banner:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		// background: rgba(64, 72, 80, 0.25);
		background: rgba(129, 139, 151, 0.25);
	}

	#banner .inner {
		position: relative;
		z-index: 1;
	}

		#banner .inner :last-child {
			margin-bottom: 0;
		}

		#banner .inner h2 {
			// color: #fec65a;
			opacity: 1;
		}

	#banner h2, #banner h3, #banner h4, #banner h5, #banner h6 {
		color: white;
		text-transform: uppercase;
	}
	// #banner h2 {
	// 		font-size: 2.6em;
	// }

	// #banner .button.alt {
	// 	box-shadow: inset 0 0 0 1px rgba(144, 144, 144, 0.75);
	// 	color: #ffffff !important;
	// }

	// 	#banner .button.alt:hover {
	// 		background-color: rgba(144, 144, 144, 0.275);
	// 	}

	// 	#banner .button.alt:active {
	// 		background-color: rgba(144, 144, 144, 0.4);
	// 	}

	// 	#banner .button.alt.icon:before {
	// 		color: #c1c1c1;
	// 	}

	// #banner .button {
	// 	min-width: 12em;
	// }



</style>