<template>
    <v-app-bar flat color="white" app>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
            dark
            app
        >
            <v-list>
                <v-list-item
                v-for="item in items"
                :key="item.title"
                :to="item.url"
                style="text-decoration: none; color: inherit; text-align: left; font-weight: 300"
                >
                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </v-app-bar>
</template>


<script>

export default {

    components: {
    },
    data: () => {
        return {
            drawer: null,
            items: [
                { title: 'SSB Home', url: "/", icon: 'dashboard' },
                { title: 'All Boxed Meals', url: "/menu", icon: 'question_answer' },
                { title: 'Breakfast Bistro', url: "/breakfast", icon: 'question_answer' },
                { title: 'Lunchly', url: "/lunchly", icon: 'question_answer' },
                { title: "Graziano's", url: "/grazi", icon: 'question_answer' },
                { title: 'Achiote Grill', url: "/achiote", icon: 'question_answer' },
                { title: 'Shoyu Asian Eatery', url: "/shoyu", icon: 'question_answer' },
                { title: 'Family Style Menu', url: "/", icon: 'question_answer' },
                { title: 'Our Services', url: "/about", icon: 'mdi-help-box' },
                { title: 'Gallery', url: "/gallery", icon: 'question_answer' },
                { title: 'Contact', url: "/contact", icon: 'mdi-help-box' },
            ],
        }
    }
}


</script>

<style lang="scss" scoped>

</style>