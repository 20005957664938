<template>
    <!-- <nav> -->
        <!-- <v-app-bar flat prominent color="white"> -->
        <!-- <> -->
        <v-app-bar flat class="px-0" app>
            <v-btn text x-large to="/" class="grey--text text--darken-2 font-weight-bold">
                <v-icon left>mdi-home</v-icon>
                SSB
            </v-btn>
            <v-spacer/>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text class="grey--text text--darken-1 text-capitalize font-weight-regular" v-bind="attrs" v-on="on">
                        <v-icon left>mdi-silverware</v-icon>
                        Individual Boxes
                    </v-btn>
                </template>    
                <span>We're updating our menus.<br/>Please contact us for more information/pricing.</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text class="grey--text text--darken-1 text-capitalize font-weight-regular" v-bind="attrs" v-on="on">
                        <v-icon left>mdi-silverware</v-icon>
                        Family Style
                    </v-btn>
                </template>    
                <span>Coming Soon!<br/>Please contact us for more information.</span>
            </v-tooltip>
            <v-btn text to="/about" class="grey--text text--darken-1 text-capitalize font-weight-regular">
                <v-icon left>mdi-room-service-outline</v-icon>
                Our Services
            </v-btn>
            <v-btn text to="/gallery" class="grey--text text--darken-1 text-capitalize font-weight-regular">
                <v-icon left>mdi-image-filter-center-focus</v-icon>
                Gallery
            </v-btn>
            <v-btn text to="/contact" class="grey--text text--darken-1 text-capitalize font-weight-regular">
                <v-icon left>mdi-face-agent</v-icon>
                CONTACT
            </v-btn>
        </v-app-bar>
    <!-- </nav> -->
</template>


<script>
//grey color used in ion: #555f66
export default {

    components: {
    },
    data: () => {
        return {
            drawer: null,
            individual_boxes: [
                { title: 'All Boxed Meals', link: '/menu', icon:'mdi-food-takeout-box'},
                { title: 'Breakfast Bistro Menu', link: '/breakfast', icon:'mdi-egg-fried'},
                { title: 'Lunchly Menu', link: '/lunchly', icon:'mdi-baguette'},
                { title: "Graziano's Menu", link: 'grazi', icon:'mdi-pasta'},
                { title: 'Achiote Grill Menu', link: 'achiote', icon:'mdi-fruit-pineapple'},
                { title: 'Shoyu Asian Eatery Menu', link: 'shoyu', icon:'mdi-noodles'},
            ],
        }
    }
}


</script>

<style lang="scss" scoped>

// .v-btn--active {
//     background-color: #E8B8D6; // #BF1866;
// }

// .v-btn--active::before {
//     opacity: 0 !important; //removes the gray background after button is clicked
// }


</style>